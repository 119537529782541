<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="$t('pages.settings.subtitle.fileHeaders')" />

		<f-reset-order-products />
		<f-card-spacer />

		<f-reset-inventory />
		<f-card-spacer />

		<f-reset-pos />
	</div>
</template>

<script>
import FResetOrderProducts from "../../../components/settings/fileHeaders/ResetOrderProducts.vue";
import FResetInventory from "../../../components/settings/fileHeaders/ResetInventory.vue";
import FResetPos from "../../../components/settings/fileHeaders/ResetPOS.vue";

export default {
	components: {
		FResetOrderProducts,
		FResetInventory,
		FResetPos,
	},
};
</script>
