<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>
					{{ $t("pages.settings.fileHeaders.productsInOrders") }}
				</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="8">
				<v-select
					v-model="distributorID"
					outlined
					dense
					hide-details="auto"
					:items="distributors"
					item-text="name"
					item-value="id"
					:label="$t('labels.distributor')" />
			</v-col>
			<v-col cols="4">
				<v-btn
					elevation="0"
					color="primary"
					:disabled="!distributorID"
					@click="reset">
					{{ $t("labels.btnClean") }}
				</v-btn>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	data: () => ({
		distributors: [],
		distributorID: null,
	}),
	created() {
		this.getData();
	},
	methods: {
		reset() {
			this.$store.dispatch("system/loading", "data");
			this.$store.dispatch("system/resetOrderProducts", this.distributorID);
			this.$store.dispatch("system/loading", "done");
			this.$store.commit("snackbar/show", {
				content: this.$t("snackbar.fileHeadersOrderSuccess"),
				type: "success",
			});
			return;
		},
		async getData() {
			const distributors = await this.$store.dispatch("distributor/list");
			this.distributors = distributors;
		},
	},
};
</script>
